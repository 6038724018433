import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/vsts/work/1/s/src/layouts/mdlayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contact`}</h1>
    <h4>{`Email`}</h4>
    <p>{`For Sales or order queries, email us out at `}<a parentName="p" {...{
        "href": "mailto:contact@dynos.io"
      }}>{`contact@dynos.io`}</a></p>
    <br />
    <h4>{`Address`}</h4>
    <p>{`Administration and Operations : F-2, Sector-8, Noida-201301 (Delhi-NCR)`}</p>
    <br />
    <p>{`Regd. Office : Akshay Tech Park, Plot 72-73,EPIP Zone, Whitefield, Bengaluru(KA)-560066`}</p>
    <br />
    <p>{`USA Office   : Dynos Inc, 44679, Endicott, Dr. Suite 300#504, Ashburn,VA-20147`}</p>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      